<template>
  <div class="hipQ"> 
    <h2>髋关节调查问卷</h2>
    <van-form @submit="lastStep">
      <h2>疼痛：</h2>
      <p class="question">1. 您多久会经历一次髋关节疼痛?</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.painQ1" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">从不</div>
          <div class="marks-text">每月</div>
          <div class="marks-text">每周</div>
          <div class="marks-text">每天</div>
          <div class="marks-text">总是</div>
        </div>
      </div>
      <div class="line"></div>
      <p class="question">在接下来的活动中，您上周经历过什么样的髋关节疼痛？</p>
      <p class="question">2. 在平地上行走。</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.painQ2" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <p class="question">3. 上下楼梯。</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.painQ3" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <p class="question">4. 坐着或躺着。</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.painQ4" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <div class="line"></div>
      <h2>功能，日常活动：</h2>
      <p class="question">以下问题与您的身体功能有关。指的是您四处活动和照顾自己的能力。对于以下每一项活动，请注明您在上周因髋关节而经历的困难程度。</p>
      <p class="question">5. 从坐位起来。</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.functionQ1" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <p class="question">6. 站立。</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.functionQ2" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <p class="question">7. 上下车。</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.functionQ3" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <p class="question">8. 在不平的路面上行走。</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.functionQ4" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <div class="line"></div>
      <h2>生活质量：</h2>
      <p class="question">9. 您多久会意识到您的髋关节问题？</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.qolQ1" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">从不</div>
          <div class="marks-text">每月</div>
          <div class="marks-text">每周</div>
          <div class="marks-text">每天</div>
          <div class="marks-text">一直</div>
        </div>
      </div>
      <div class="line"></div>
      <p class="question">10. 您是否改变了您的生活方式，以避免那些可能对您的髋关节造成损伤的活动？</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.qolQ2" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">适度</div>
          <div class="marks-text">较多</div>
          <div class="marks-text">完全</div>
        </div>
      </div>
      <p class="question">11. 您是否对您的髋关节缺乏信心？</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.qolQ3" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>
      <p class="question">12. 总的来说，您的髋关节有多大的问题？</p>
      <div class="slider-wrap">
        <van-slider v-model="hipQData.qolQ4" max="4" />
        <div class="split-line"><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">没有</div>
          <div class="marks-text">轻微</div>
          <div class="marks-text">中度</div>
          <div class="marks-text">重度</div>
          <div class="marks-text">严重</div>
        </div>
      </div>

      <van-button block type="info" native-type="submit">提交</van-button>
    </van-form>
  </div>
</template>
<script>
import { addHipQ } from "@/api/getData"

export default {
  name: "HipQ",
  data() {
    return {
      qName:'',
      hipQData:{
        userId: '',
        painQ1 : 0,// '疼痛问题1',
        painQ2 : 0,// '疼痛问题2',
        painQ3 : 0,// '疼痛问题3',
        painQ4 : 0,// '疼痛问题4',
        functionQ1 : 0,// '功能问题1',
        functionQ2 : 0,// '功能问题2',
        functionQ3 : 0,// '功能问题3',
        functionQ4 : 0,// '功能问题4',
        qolQ1 : 0,// '生活问题1',
        qolQ2 : 0,// '生活问题2',
        qolQ3 : 0,// '生活问题3',
        qolQ4 : 0,// '生活问题4',
      }      
    }
  },
  methods: {   
    //提交表单
    lastStep(){
      addHipQ(this.hipQData).then(res => {        
        this.$message.success('已成功添加髋关节训练方案!');
        this.$router.push({path: "/",query: { preId: res.data}})
      })        
    },
  },
  mounted() {
    this.hipQData.userId = this.vUserId;
  }
}
</script>

<style lang="scss" scoped>
.hipQ {
  padding: 0 .16rem;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  h2 {
    padding-top: 0.48rem;
    font-size: .18rem;
    font-weight: 700;
    line-height: 0.32rem;
    opacity: 0.9;
  }

  .van-form {
    font-size: 0.14rem;
    font-weight: 700;

    .question {
      line-height: 0.24rem;
      margin: 0.4rem 0 0.24rem;
      color: #3d3d3d;
    }

    .van-button {
      height: 0.48rem;
      margin: 0.64rem 0 0.32rem;
      border-radius: 0.4rem;
    }
  }
}
</style>